<template>
  <div>
    <p v-if="!results.length" class="text-center mt-5">
      Результаты оценки будут доступны в течение 1-3 недель после оценки.
      Ожидайте уведомлений.
    </p>
    <div v-else>
      <text-btn icon="mdi-arrow-left" @click="$emit('tab-selected', 'Main')"
        >На главную</text-btn
      >

      <v-select
        style="max-width: 220px"
        v-model="selectedResult"
        :items="results"
        item-text="date"
        item-value="id"
        return-object
        label="Выберите дату оценки"
        dense
        class="mr-3 my-3"
      >
      </v-select>

      <h3 class="title text-center mb-3">Ваши результаты оценки готовы!</h3>

      <div>
        <p class="mb-3">
          Перед изучением результатов оценки ознакомьтесь с рекомендациями по
          работе с отчетом:
        </p>
        <a class="block-link" href="#" target="_blank"
          >Как работать с отчетом</a
        >
      </div>

      <v-card class="pa-5 mt-8 mb-6" elevation="4">
        По итогам оценки мы можем сделать вывод, что уровень развития
        компетенций необходимых для успешной работы в выбранной роли,
        соответствует ожжиданиям
      </v-card>

      <div>
        <p class="subtitle primary--text mb-3">Результаты по компетенциям:</p>
        <v-card class="pa-3" elevation="4" height="500">
          <v-row>
            <v-spacer></v-spacer>
            <v-col sm="6" lg="5" cols="12">
              <div
                class="d-flex align-center justify-space-between text-center"
              >
                <div class="alert-text--danger">
                  Не соответствует ожиданим 1-3
                </div>
                <div class="alert-text--warning">
                  Соответствует ожиданим 4-7
                </div>
                <div class="alert-text--success">Превосходит ожиданим 8-10</div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels accordion elevation="0">
              <v-list-item-group style="min-width: 100%">
                <v-expansion-panel
                  v-for="(item, i) in selectedResult.competences"
                  :key="i"
                >
                  <v-col sm="6" lg="7" cols="12" class="px-0 pb-0">
                    <v-expansion-panel-header>
                      {{ item.indexNumber }} {{ item.name }}
                    </v-expansion-panel-header>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-expansion-panel-content>
                    <v-list class="py-0" style="overflow: hidden">
                      <v-row
                        v-for="(indicator, idx) in item.indicators"
                        align="center"
                        :key="idx"
                      >
                        <v-col sm="6" lg="7" cols="12" class="py-0 pr-0">
                          <v-list-item
                            class="py-3 pl-7"
                            @click="switchToggle(true, indicator)"
                          >
                            {{ item.indexNumber }}. {{ indicator.indexNumber }}
                            {{ indicator.name }}
                          </v-list-item>
                        </v-col>
                        <v-col sm="6" lg="5" cols="12" class="py-0">
                          <v-slider
                            dense
                            class="slider --disabled"
                            :value="10 * indicator.mark"
                            thumb-label="always"
                            :color="
                              indicator.mark <= 3
                                ? '#ff1212'
                                : indicator.mark <= 7
                                ? '#fbba00'
                                : '#84af5b'
                            "
                          >
                            <template v-slot:thumb-label style="border-radius">
                              <div style="font-size: 16px">
                                {{ indicator.mark }}
                              </div>
                            </template>
                          </v-slider>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-list-item-group>
            </v-expansion-panels>
          </v-row>
        </v-card>
      </div>

      <ItemDesc
        :strengths="selectedResult.strengths"
        :weaknesses="selectedResult.weaknesses"
      />
    </div>
    <modal :dialog="toggle" :width="800">
      <ItemDesc
        :strengths="toggle.view.strengths"
        :weaknesses="toggle.view.weaknesses"
      />
    </modal>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "@vue/composition-api";
import ItemDesc from "./results/ItemDesc";
import { useModalToggle } from "@/use/helpers";

export default {
  setup() {
    const { toggle, switchToggle } = useModalToggle();

    const results = computed(() => [
      {
        id: 1,
        date: "2021-02-08",
        strengths: "сильные стороны компетенции 1",
        weaknesses: "слабые стороны компетенции 1",
        competences: [
          {
            indexNumber: "6",
            name: "Результативность",

            indicators: [
              {
                indexNumber: "4",
                name: "Правильно расставляет приоритеты при решении задач, фокусируется на главном",
                strengths: "сильные стороны 1",
                weaknesses: "слабые стороны 1",
                mark: 4,
              },
              {
                indexNumber: "6",
                name: "Правильно расставляет приоритеты при решении задач, фокусируется на главном",
                strengths: "сильные стороны 2",
                weaknesses: "слабые стороны 2",
                mark: 1,
              },
            ],
          },
          {
            indexNumber: "8",
            name: "Результативность2",
            indicators: [
              {
                indexNumber: "1",
                name: "Правильно расставляет приоритеты при решении задач, фокусируется на главном2",
                strengths: "сильные стороны 3",
                weaknesses: "слабые стороны 3",
                mark: 9,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        date: "2021-02-12",
      },
    ]);

    const selectedResult = ref(results.value[0]);

    onMounted(() => localStorage.setItem("lastTab", "Results"));

    return {
      results,
      toggle,
      switchToggle,
      selectedResult,
    };
  },
  components: {
    ItemDesc,
  },
};
</script>

<template>
  <div class="d-flex justify-space-between mt-8">
    <div class="flex-grow-1 pr-8">
      <p class="subtitle primary--text mb-1 text-center">Сильные стороны:</p>
      <p class="text-output">
        {{ strengths }}
      </p>
    </div>
    <div class="flex-grow-1">
      <p class="subtitle primary--text mb-1 text-center">Зоны развития:</p>
      <p class="text-output">{{ weaknesses }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    strengths: String,
    weaknesses: String,
  },
};
</script>
